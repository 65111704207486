import { Box, Button, Input, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'

const Inputs = ({ HandleRegistration, loading }) => {

    const BOX_STYLES = {
        padding: "20px 0px",
        paddingLeft: "40px",
        flex: 1,
        '@media screen and (max-width:700px)': {
            paddingLeft: 0,
            paddingTop: '50px'
        }
    }
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();

    const Submit = () => {
        if (phone && email) {
            HandleRegistration({ phone: phone, email: email })
        }
    }
    return (<Box sx={BOX_STYLES} dir="rtl">
        <Text fontWeight={600} fontSize={'20px'} marginBottom="40px" textAlign='center' style={{ fontFamily: 'var(--main-font)' }}>سجل الآن لتستفيد من الحسم</Text>
        <Stack spacing={4} justifyContent="center" display="flex" style={{ flexDirection: 'column' }}>

            <Input
                color='#196ec2'
                name='phone'
                placeholder='رقم جوالك'
                _placeholder={{ color: '#196ec2', opacity: 0.7, fontSize: '15px', direction: 'rtl', textAlign: 'right !important' }}
                style={{ borderRadius: '25px', borderColor: '#196ec2', fontSize: '15px', textAlign: 'left', fontFamily: 'var(--main-font)' }}
                size='lg'
                type="tel"
                onChange={(e) => setPhone(e.target.value)}
            />

            <Input
                color='#196ec2'
                placeholder='بريدك الالكتروني'
                _placeholder={{ color: '#196ec2', opacity: 0.7, fontSize: '15px', direction: 'rtl', textAlign: 'right !important' }}
                style={{ borderRadius: '25px', borderColor: '#196ec2', fontSize: '15px', textAlign: 'left', fontFamily: 'var(--main-font)' }}
                size='lg'
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}

            />

            <Button
                onClick={() => Submit()}
                isLoading={loading}
                colorScheme='blue'
                borderRadius="50px"
                style={{ background: '#196ec2', width: 'fit-content', padding: '20px 20px 25px', margin: '20px auto 0px', fontFamily: 'var(--main-font)' }}
            // spinner={<BeatLoader size={8} color='white' />}
            >
                سجل
            </Button>

        </Stack>
    </Box >)
}

export default Inputs