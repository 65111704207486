import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import img from '../../assets/images/header.svg'
import Navbar from './Navbar'

const Header = ({ setOpenContact, HandleOpen }) => {

    const SOON_STYLES = {
        zIndex: 2,
        transform: 'rotate(-40deg) translate(-10px,67px)',
        fontSize: '45px',
        position: 'absolute',
        left: '0',
        fontFamily: 'var(--second-font)',
        fontWeight: 900,
        '@media screen and (max-width:700px)': {
            transform: 'rotate(-40deg) translate(-10px,35px)',
        },
        '@media screen and (max-width:450px)': {
            fontSize: '35px',
        },
    }
    return (
        <Box>
            <Box dir='ltr'>
                <img src={img} alt="svg-header" style={{ width: '600px', position: 'absolute', left: '0', top: '0', zIndex: 1 }} />
                <Text color='white' sx={SOON_STYLES}>SOON</Text>
            </Box>

            <Navbar setOpenContact={setOpenContact} HandleOpen={HandleOpen} />

        </Box>)
}

export default Header