import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import './App.css';
import Body from './components/body/Body';
import Header from './components/header/Header';
import Images from './components/header/Images';
import ContactUsModal from './components/modals/contactUs-modal/contactUsModal';
import RegistrationModal from './components/modals/registration-modal/registrationModal';

function App() {
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  const HandleOpen = () => {
    setOpen(true);
    document.querySelector('body').classList.add('blur');
  }
  const HandleClose = () => {
    setOpen(false);
    document.querySelector('body').classList.remove('blur');
  }

  return (
    <div className="App" dir='rtl'>
      <Header HandleOpen={HandleOpen} setOpenContact={setOpenContact} />
      <br />

      <Box >
        <Images />
      </Box>

      <Body HandleOpen={HandleOpen} />
      <RegistrationModal open={open} HandleClose={HandleClose} />
      <ContactUsModal open={openContact} setOpen={setOpenContact} />
    </div>
  );
}

export default App;
