import { Box, Text, Center, Divider } from '@chakra-ui/react'
import React from 'react'
import icon1 from '../../../../assets/images/icon-1.png'
import icon2 from '../../../../assets/images/icon-2.png'

const Features = () => {
    const DIV_STYLES = {
        '@media screen and (max-width:700px)': {
            display: 'none'
        }
    }

    const LIST_STYLES = {
        color: '#196ec2',
        fontSize: '20px',
        fontWeight: 600,
        display: 'flex',
        fontFamily: 'var(--main-font)',
        '@media screen and (max-width:700px)': {
            fontSize: '16px',
        }
    }

    const NUM_STYLES = {
        fontWeight: 700,
        fontSize: '26px',
        marginLeft: '5px',
        '@media screen and (max-width:700px)': {
            fontSize: '22px',
        }
    }
    const BOX_STYLES = {
        padding: '20px',
        '@media screen and (max-width:700px)': {
            padding: '0px',
        }
    }
    return (<Box display='flex' flex={1} dir="rtl">
        <Center height='280px' sx={DIV_STYLES} >
            <Divider orientation='vertical' style={{ color: '#196ec2' }} />
        </Center>
        <Box sx={BOX_STYLES}>

            <Text fontWeight={600} fontSize={'20px'} marginBottom="40px" textAlign='center' style={{ fontFamily: 'var(--main-font)' }} >مزايا التسجيل</Text>

            <Box marginBottom="50px">
                <Box display="flex" marginBottom="20px">
                    <img src={icon1} alt='icon-1' style={{ width: '40px', marginLeft: '10px' }} />
                    <Box sx={LIST_STYLES}  >
                        <Text sx={NUM_STYLES}>30</Text> يوم مجاناً</Box>
                </Box>
                <Box display="flex">
                    <img src={icon2} alt='icon-1' style={{ width: '40px', marginLeft: '10px' }} />
                    <Text sx={LIST_STYLES}  >اشتراك شهر مقابل سنة</Text>
                </Box>
            </Box>
            <Box display="flex" color="#196ec2" style={{ fontFamily: 'var(--main-font)' }}>
                <Text marginLeft="5px" fontWeight={500}>ملاحظة : </Text>
                <Text>لن تدفع الآن أي مبلغ .</Text>
            </Box>
        </Box>
    </Box >
    )
}

export default Features