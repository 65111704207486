import React, { useState } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Inputs from './inputs/Inputs'
import Features from './features/Features'
import axios from 'axios'
import { useToast } from '@chakra-ui/react'

const MODAL_STYLES = {
    position: 'absolute',
    zIndex: 10,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    top: 0,
    overflow: 'hidden'
}

const MODAL_BODY_STYLES = {
    background: '#fff',
    borderRadius: '50px',
    width: '800px',
    padding: '50px 50px',
    position: 'relative',
    margin: 'auto',
    zIndex: 12,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 22px #d0d4d5',
    transform: 'scale(1.1)',
    '@media screen and (max-width:700px)': {
        width: 'calc(100vw - 15vw)',
        display: 'block',
        margin: '50px auto',
        height: ' calc(100vh - 100px)',
        padding: '50px 20px',
        overflowY: 'scroll',
        '::-webkit-scrollbar': {
            width: '2px'
        }


    },
}

const MODAL = {
    position: 'static',
    opacity: 0,
    zIndex: -1,
    visibility: 'hidden',
    '&.active': {
        zIndex: 1,
        opacity: 1,
        visibility: 'visible',

    }
}

const RegistrationModal = ({ open, HandleClose }) => {

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useToast()


    const Close = () => {
        if (!loading) {
            setSuccess(false);
            HandleClose();
        }

    }
    const HandleRegistration = async (obj) => {
        setLoading(true);
        await axios.post(`https://960r7tq1tb.execute-api.us-east-2.amazonaws.com/prod/user/public/offer`,
            obj
        ).then(res => {
            setSuccess(true);
            setLoading(false);

        }).catch((error) => {
            console.log(error);
            setLoading(false);
            toast({
                title: "حدث خطأ ما، يرجى إعادة المحاولة",
                duration: 9000,
                positions: 'top',
                variant: 'left-accent',
                isClosable: true,
            })
        });
    }

    return (
        <Box sx={MODAL} className={open ? 'active' : ''}>
            <Box sx={MODAL_STYLES} >
                <Box className='overlay' onClick={() => Close()}></Box>

                <Box sx={MODAL_BODY_STYLES} dir="ltr">
                    {!success ? <>
                        <Features />
                        <Inputs HandleRegistration={HandleRegistration} loading={loading} />
                    </>
                        : <Box textAlign='center' width='100%' padding='30px 0px 40px'>
                            <Text color="#0077FF" fontSize='30px' fontWeight={600} marginBottom='20px' style={{ fontFamily: 'var(--main-font)' }}>شكراً لكم</Text>
                            <Text color="#00B135" fontSize='22px' fontWeight={600} marginBottom='15px' style={{ fontFamily: 'var(--main-font)' }}>تم التسجيل بنجاح</Text>
                            <Text color="#333" fontSize='22px' fontWeight={600} style={{ fontFamily: 'var(--main-font)' }} >سوف يتم اعلامكم عند اطلاق التطبيق لتكونوا اول المستفيدين</Text>
                        </Box>}

                </Box>
            </Box>
        </Box>

    )
}

export default RegistrationModal