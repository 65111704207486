import React from 'react'
// import anime from 'animejs';
import { Box } from '@chakra-ui/react'
import wave from '../../assets/images/wave.svg'
import { ReactComponent as Wav } from '../../assets/images/w6.svg'

const Wave = () => {
    // const wave1 = "M-1321.09-30.31s433.8-42.7,817.7,206.2,637.6-182.5,1813.2-158.8,1246.7,395.8,1246.7,395.8l-94.8,350.8s-298.8-564.1-746.2-635.4c-2.2-.3-575.7-121-1121.1,26.1C26.61,307.49-517.69,726-681.19,604.89c-320-237-504.9-320-694.5-279.7S-1321.09-30.31-1321.09-30.31Z";
    // const wave2 = "M-689-113.3S-389.72-142.73-124.8,29,386.63-1.16,1197.75,15.19s788.67,177.32,788.67,177.32S1674.65,30,1222.63,89.82s-463.42,451-950.72,461.54S-595.78,104.2-726.6,132-689-113.3-689-113.3Z";
    // const wave3 = "M0 250L50 238.095C100 226.19 200 202.381 300 166.667C400 130.952 500 83.3333 600 101.19C700 119.048 800 202.381 900 214.286C1000 226.19 1100 166.667 1150 136.905L1200 107.143V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V250Z";
    //const wave4 = "M0 125L50 111.111C100 97.2222 200 69.4444 300 97.2222C400 125 500 208.333 600 236.111C700 263.889 800 236.111 900 229.167C1000 222.222 1100 236.111 1150 243.056L1200 250V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V125Z";

    // anime({
    //     targets: '.wave-top > path',
    //     easing: 'linear',
    //     duration: 1000,
    //     loop: true,
    //     d: [
    //         { value: [wave1, wave2] },
    //         // { value: wave1 },
    //         // { value: wave2 },
    //         { value: wave2 },
    //     ],
    // });
    const BOX_STYLES = {
        marginTop: '150px',

        '@media screen and (max-width:700px)': {
            marginTop: '0px',

        },
        '.large': {
            '@media screen and (max-width:700px)': {
                display: 'none'
            }
        },
        '.small': {
            '@media screen and (min-width:700px)': {
                display: 'none'
            }
        }
    }

    return (
        <Box position='relative' sx={BOX_STYLES} >
            <Box className='large'>
                <Wav />
            </Box>
            <Box className='small'>
                <img src={wave} alt="wave" className='wave' />
            </Box>
        </Box>
    )
}

export default Wave