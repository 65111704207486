import { Box } from '@chakra-ui/react'
import React from 'react'
import phone from '../../assets/images/phone.svg'
import man from '../../assets/images/man.svg'
import Title from './Title'

const IMAGES_BOX = {
    position: 'relative',
    flex: 1,
    top: '-140px',
    '@media screen and (max-width:1100px)': {
        top: '-100px',

    },
    '.man-img': {
        position: 'relative',
        transition: '0.5s',
        transform:
            'translate(29.11%, -9.0%) rotate(-40.5deg) scale(0)'
        // 'translate(-13.89%, -3.3%) rotate(-0.5deg) scale(0)'
        ,
        top: '200px',
        perspective: 1000
    },
    '&:hover': {
        '.man-img': {
            transform:
                'translate(29.11%, -9.0%) rotate(-40.5deg) scale(1)',
            top: '0px',

            // 'translate(-13.89%, -3.3%) rotate(-0.5deg) scale(1)'
        }
    },
    '@media screen and (max-width:700px)': {
        display: 'none'
    }

}

const BOX_STYLES = {
    position: 'relative',
    width: '100%',
    height: '600px',
    background: '',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (max-width:700px)': {
        display: 'block',
    },
}

const MAN_BOX_STYLES = {
    width: '700px',
    height: '600px',
    position: 'relative',
    transform: 'scale(1.1)',
    '@media screen and (max-width:1200px)': {
        transform: 'scale(1)',
    },
    '@media screen and (max-width:1000px)': {
        width: '500px',
        height: '430px'
    },
}
const Images = () => {
    return (
        <Box sx={BOX_STYLES}>

            <Title />
            <Box sx={IMAGES_BOX} >
                <Box sx={MAN_BOX_STYLES}>
                    <img src={phone} alt="phone" style={{ position: 'absolute', width: '100%' }} />

                    <Box style={{
                        position: 'absolute', zIndex: 5, width: '75%', overflow: 'hidden',
                        transform: 'rotate(41deg)',
                        height: '79%'
                    }} >
                        <img className='man-img' src={man} alt="man" style={{ width: '100%', transformOrigin: 'bottom' }} />
                    </Box>
                </Box>

            </Box>




        </Box>)
}

export default Images