import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const NAV_STYLES = {
    display: 'flex',
    position: 'relative',
    zIndex: 10,
    paddingRight: '50px',
    'p': {
        margin: '40px 50px',
        fontFamily: 'var(--four-font)',
        cursor: 'pointer',
        fontSize: '22px'
    },
    '@media screen and (max-width:700px)': {
        paddingRight: '0px',
    },
}
const Navbar = ({ HandleOpen, setOpenContact }) => {
    return (
        <Box sx={NAV_STYLES}>
            {/* <Text><a href='#body'>مميزات التطبيق</a></Text> */}
            <Text onClick={() => HandleOpen()}>التسجيل</Text>
            {/* <Text onClick={() => setOpenContact(true)}>تواصل معنا</Text> */}

        </Box>
    )
}

export default Navbar