import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import arrow1 from '../../assets/images/arrow.svg'
import Wave from './wave'
// import wave from '../../assets/images/wave.svg'
// import { ReactComponent as Wave } from '../../assets/images/wave3.svg'


const ROW_BOX_STYLES = {

    display: 'flex',
    width: '85%',
    margin: 'auto',
    position: 'relative',
    // zIndex: 2,
    marginTop: '-280px',
    '@media screen and (max-width:1050px)': {
        width: '95%',
        marginTop: '-150px',

    },
    '@media screen and (max-width:700px)': {
        display: 'block',
        marginTop: '0px',

    },

    // paddingTop: '200px',
}
const COL_BOX_STYLES1 = {
    flex: '1',
    padding: '20px 0',
    '@media screen and (max-width:700px)': {
        padding: '20px ',
    },
}
const COL_BOX_STYLES2 = {
    position: 'relative',
    zIndex: 5,
    flex: '1',

    '@media screen and (max-width:700px)': {
        justifyContent: 'center',
        marginTop: '30px'

    }
}

const TITLE_STYLES = {
    color: '#0090FF',
    width: 'fit-content',
    fontSize: '30px',
    paddingBottom: '25px',
    paddingLeft: '5px',
    borderBottom: '2px solid #0090FF',

    fontFamily: 'var(--four-font)',
    marginBottom: '25px',

    '@media screen and (max-width:1050px)': {
        fontSize: '26px',
    }
}

const NUM_STYLES = {
    color: '#0090FF',
    fontSize: '45px',
    paddingLeft: '15px',
    position: 'relative',
    top: '-10px',
    fontWeight: '600',
    fontFamily: 'var(--four-font)',

    '@media screen and (max-width:1050px)': {
        fontSize: '32px',

    }
}

const LIST_STYLES = {
    marginTop: '1px',
    fontSize: '26px',
    fontWeight: '400',
    lineHeight: '1.9',
    fontFamily: 'var(--main-font)',

    '@media screen and (max-width:1050px)': {
        fontSize: '22px',

    }
}

const TITLE1 = {
    fontSize: '50px',
    textAlign: 'center',
    fontFamily: 'var(--four-font)',
    '@media screen and (max-width:1050px)': {
        fontSize: '35px',
    },

    '@media screen and (max-width:500px)': {
        fontSize: '25px',
    }

}

const TITLE2 = {
    fontSize: '50px',
    textAlign: 'left',
    fontFamily: 'var(--four-font)',
    '@media screen and (max-width:1050px)': {
        fontSize: '35px',
    },
    '@media screen and (max-width:500px)': {
        fontSize: '25px',
    }
}
const BUTTON_STYLES = {
    position: 'relative',
    zIndex: 5,
    textAlign: 'center',
    fontSize: '35px',
    fontWeight: '900',
    border: '1px solid',
    padding: '10px 20px',
    borderRadius: '25px',
    cursor: 'pointer',
    width: 'fit-content',
    margin: 'auto',
    transition: 'all .5s',
    fontFamily: 'var(--four-font)',
    marginBottom: '100px',
    marginTop: '-30px',

    '&:hover': {
        transform: 'scale(1.5) rotate(10deg)',
        padding: '10px 30px'
    },
    '@media screen and (max-width:1050px)': {
        fontSize: '30px',

    },
    '@media screen and (max-width:700px)': {
        marginTop: '30px',

    }
}

const ARROW_IMAGE = {

    '@media screen and (max-width:700px)': {
        display: 'none',

    }
}

const TEXT_STYLES = {
    display: 'flex',
    justifyContent: 'left',
    '@media screen and (max-width:700px)': {
        justifyContent: 'center',
    }
}
const Body = ({ HandleOpen }) => {



    return (
        <Box id='body' style={{ position: 'relative' }}>
            <Box style={{ position: 'relative' }}>
                <Wave />

            </Box>
            <Box>

            </Box>
            {/* <Wave /> */}
            {/* <picture src={wave} style={{ width: '100%', position: 'relative', top: '0px', zIndex: 10000000 }} /> */}
            <Box sx={ROW_BOX_STYLES}>
                <Box sx={COL_BOX_STYLES1} >
                    <Text sx={TITLE_STYLES}>الأسلوب الراقي للتواصل</Text>
                    <Box display='flex' marginTop='15px'>
                        <Text sx={NUM_STYLES}>1</Text>
                        <Text sx={LIST_STYLES}>تعريف المتصل وسبب اتصاله بطريقة مبتكرة</Text>
                    </Box>
                    <Box display='flex' marginTop='5px'>
                        <Text sx={NUM_STYLES}>2</Text>
                        <Text sx={LIST_STYLES}>لن تفوتك اتصالات أثناء النوم أو الانشغال</Text>
                    </Box>
                    <Box display='flex' marginTop='5px'>
                        <Text sx={NUM_STYLES}>3</Text>
                        <Text sx={LIST_STYLES}>تحديد المدة المناسبة  قبل الرد <br />والكثير من الميزات الرائعة</Text>
                    </Box>
                </Box>
                <Box sx={COL_BOX_STYLES2} >
                    <Box sx={TEXT_STYLES} >
                        <Box style={{
                            width: 'fit-content', fontFamily: 'var(--four-font)',
                        }}>
                            <Text sx={TITLE1}>سجل الآن</Text>
                            <Text sx={TITLE2} >واستفد من الحسم</Text>
                        </Box>
                    </Box>

                    <Box sx={ARROW_IMAGE} style={{ position: 'relative', top: '20px', zIndex: 10 }}>
                        <img src={arrow1} style={{ margin: 'auto', width: '200px' }} alt="arrow" />
                    </Box>

                </Box>

            </Box>

            <Box onClick={() => HandleOpen()}
                sx={BUTTON_STYLES}
            >التسجيل

            </Box>

        </Box>
    )
}

export default Body