import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import img from '../../assets/images/title.png'

const Title = () => {
    return (
        <Box style={{ position: 'relative', flex: 1, textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <Box style={{ marginTop: '70px' }}>
                <img src={img} alt="it's me" style={{ height: 'auto', width: '220px', marginRight: '10px', margin: 'auto' }} />

                <Text style={{ fontSize: '65px', fontFamily: 'var(--main-font)' }}>حيّاك</Text>
                <Text style={{ fontSize: '30px', direction: 'ltr', fontWeight: 500, letterSpacing: '1px', fontFamily: 'var(--second-font)' }}>You are welcome !</Text>
            </Box>


        </Box>
    )
}

export default Title