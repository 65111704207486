import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons'

const MODAL_STYLES = {
    position: 'absolute',
    zIndex: 10,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    top: 0,
}

const MODAL_BODY_STYLES = {
    background: '#fff',
    borderRadius: '50px',
    width: '400px',
    padding: '50px 70px',
    position: 'relative',
    margin: 'auto',
    zIndex: 12,
    boxShadow: '0px 3px 10px #94989a'
}

const MODAL = {
    position: 'static',
    opacity: 0,
    zIndex: -1,
    visibility: 'hidden',
    '&.active': {
        zIndex: 1,
        opacity: 1,
        visibility: 'visible',

    }
}

const ContactUsModal = ({ open, setOpen }) => {
    return (
        <Box sx={MODAL} className={open ? 'active' : ''}>
            <Box sx={MODAL_STYLES} >
                <Box className='overlay' onClick={() => setOpen(false)}></Box>

                <Box sx={MODAL_BODY_STYLES}>
                    <Text textAlign='center' fontWeight={600} fontSize="22px" marginBottom="20px">تواصل معنا</Text>
                    <Box>
                        <Box display="flex" marginBottom="10px">
                            <PhoneIcon style={{ width: '30px', transform: 'scaleX(-1)', marginTop: '3px', fontSize: '16px' }} />
                            <Text>099999999999</Text>
                        </Box>
                        <Box display="flex">
                            <EmailIcon style={{ width: '30px', marginTop: '5px', fontSize: '18px' }} />
                            <Text>example@gmail.com</Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}

export default ContactUsModal